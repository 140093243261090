
































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import ContractSavings from '@/vue-app/components/ContractSavings.vue';
import ModalPrepareDocumentsPocketViewModel from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-pocket/modal-prepare-documents-pocket-view-model';

@Component({ name: 'ModalPrepareDocumentsPocket', components: { ContractSavings } })
export default class ModalPrepareDocumentsPocket extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  private readonly modal_prepare_documents_pocket = Vue.observable(
    new ModalPrepareDocumentsPocketViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }
}
